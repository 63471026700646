import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MyComponent from './MyComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));
function App() {
  return (
    <div className="App">
      <MyComponent />
    </div>
  );
}

root.render(<App/>);