import React, { useState } from 'react';

function MyComponent() {
  const [inputValue, setInputValue] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [errorResponse, setErrorResponse] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    fetch(`https://backend.datascope.ai/greet/${inputValue}`)
      .then(x => console.log(x))
      .then(response => response.json())
      .then(data => {
            console.log(data);
            setApiResponse(data);
        })
      .catch(error => {
            console.error('Error:', error);
            setErrorResponse('Error:', error);
        });
  };

  return (
    <div>
      <h1>Hello Hello World 1</h1>
      <input type="text" value={inputValue} onChange={handleInputChange} />
      <button onClick={handleButtonClick}>Send to API</button>
      {apiResponse && <div>Response from API: {JSON.stringify(apiResponse)}</div>}
      {errorResponse && <div>Response from API: {JSON.stringify(errorResponse)}</div>}
    </div>
  );
}

export default MyComponent;
